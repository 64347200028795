import React, {useEffect, useRef} from 'react';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import htmr from 'htmr';
import styles from './payments.module.scss';
import PaymentHeader from './Shared/PaymentHeader';
import PaymentFooter from './Shared/PaymentFooter';
import {triggerPageViewAction, triggerCTAClickAction,
  PAGE_KINDS, redirectWithDelay, getElementRefByClassName} from './Utils';
import {Button} from 'react-bootstrap';
/**
 *
 *
 * @return {*}
 */
function SubscriptionThankyouPage({location}) {
  const locale = 'en';
  const language = locale?.split('-')[0] || 'en';
  const gatewayName = location?.state?.product?.gateway;
  const studentId = cookie.load('shawacademy_leadId');
  // navigation state => prop somehow surviving page refresh.
  // check alternatives if failure cases can be simulated

  const trialSubscriptionContent = {
    heading: 'Hi,',
    info: `Your <span class="free-trial-period">4 Weeks</span> Free Course has
    started – make sure to look out for our welcome email!`,
    subinfo: `Students tell us they have better success when they schedule
    their class times. Click Continue to choose 2 days for your class
    schedule. You can also choose a time which suits you best. Then just look
    around, or watch a trailer for a taste of what’s to come in your
    first class!`,
  };

  const paidSubscriptionContent = {
    heading: `Your membership has started – make sure to look out for our
    welcome email!`,
    info: `Students tell us they have better success when they schedule their
    class times. Click continue to choose the schedule that suits you best.
    Then feel free to look around or watch a trailer for a taste of what's to
    come in your first class!`,
  };

  const content =
  location.state?.product?.freeTrialPeriod > 0 ?
      trialSubscriptionContent : paidSubscriptionContent;
  const coursePicked =location.state?.student?.attributes
      .profile.course_picked||cookie.load('coursePicked');

  const subscriptionInfoRef = useRef(null);

  // TODO: duplicate.. move to utils
  const getFreeTrialPeriodText = () => {
    let freeTrialText = `4 Weeks`; // default text
    if (location.state?.product?.freeTrialPeriod > 0) {
      if (location.state?.product?.freeTrialPeriod === 14) {
        freeTrialText = `2 Weeks`;
      } else if (location.state?.product?.freeTrialPeriod != 28) {
        freeTrialText =
          `${
            location.state?.product?.freeTrialPeriod
          } ${
            location.state?.product?.freeTrialPeriodUnit
          }`;
      }
    }
    return freeTrialText;
  };

  useEffect(() => {
    if (subscriptionInfoRef.current) {
      const freeTrialPeriodRef =
        getElementRefByClassName(subscriptionInfoRef, 'free-trial-period');
      if (freeTrialPeriodRef) {
        freeTrialPeriodRef.textContent = getFreeTrialPeriodText();
      }
    }
  }, [location]);

  useEffect(() => {
    triggerPageViewAction(studentId, PAGE_KINDS.THANKYOU_PAGE, {
      courseSlug: coursePicked,
    });
    proceedToStudentsArea(true);
  }, []);

  const proceedToStudentsArea = (withDelay = false) => {
    let redirectUrl;
    if (coursePicked) {
      redirectUrl = `/app/${language}/register/`;
      redirectUrl += `?course_slug=${coursePicked}&action=payment-complete`;
    } else {
      redirectUrl = `/app/${language}/courses/`;
    }
    if (withDelay) {
      redirectWithDelay(redirectUrl);
    } else {
      triggerCTAClickAction(studentId,
          PAGE_KINDS.THANKYOU_PAGE,
          'Continue', 'ContinueToSchedule');
      navigate(redirectUrl);
    }
  };

  return (
    <div className={styles.paymentContainer}>
      <PaymentHeader />
      <div className={styles.paymentContainerSuccess}>
        <div className={styles.paymentContainerSuccessInner}>
          <div className={styles.paymentSuccessInfo}>
            <div className={styles.content}>
              {/* separate from contentstack */}
              {/* {location.state?.student?.attributes.name.first_name} */}
              {
                location.state?.product?.freeTrialPeriod > 0 ?
                <h3>
                  Hi&nbsp;
                  {location.state?.student?.attributes.name.first_name},
                </h3> : <h3>{content.heading}</h3>
              }
              <div className={gatewayName === 'gocardless' ?
              styles.info : styles.hideInfo}>
                Direct Debit set up successfully <br />
                You will receive notification (via email or post) within 3
                business days confirming that the mandate has been set up <br />
                <br />
              </div>
              <div ref={subscriptionInfoRef} className={styles.info}>
                {htmr(content.info)}
              </div>
              {content.subinfo && <div className={styles.subinfo}>
                {content.subinfo}
              </div>}
            </div>
            <div className={styles.bannerImg}>
              <img src='/images/payment/welcome.jpg' alt=''/>
            </div>
          </div>
          <div className={styles.paymentSuccessAction}>
            <Button onClick={() => {
              proceedToStudentsArea(false);
            }}>
              Continue
            </Button>
          </div>
        </div>
      </div>
      <PaymentFooter />
    </div>
  );
}

SubscriptionThankyouPage.defaultProps = {
  data: {},
};

SubscriptionThankyouPage.propTypes = {
  location: PropTypes.object,
  locale: PropTypes.string,
};

export default SubscriptionThankyouPage;
